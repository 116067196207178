// https://www.placemark.io/
import { Button } from 'antd';
import HomeLayout from '../blocks/layout/home';
import Seo from '../blocks/layout/seo';
import React, { useEffect, useState } from 'react';

import { FaRegBuilding } from 'react-icons/fa';
import { RiMailSendLine } from 'react-icons/ri';
import { Card, Collapse, Grid, Text } from '@geist-ui/core';
import '../styles/pricing.scss';
import { pricingOptionsPublishers, pricingOptionsDist } from '../data/pricing';
import { Link } from 'gatsby';
import { Firebase } from '../config/firebase';
import Checkout from '../blocks/dashboard/checkout';
import axios from 'axios';

const PricingPage = () => {
      const [isLoggedIn, setIsLoggedIn] = useState(false);
      const [userUid, setUserUid] = useState(null);
      const [userType, setUserType] = useState('');

      async function onChange(user) {
            if (user) {
                  const { data, error } = await axios({
                        url: `${process.env.GATSBY_API_BASE_URL}/getUser`,
                        method: 'post',
                        data: {
                              uid: user.uid,
                        },
                  });
                  setUserType(data.userType);
                  setUserUid(user.uid);
                  setIsLoggedIn(true);
            }
      }

      useEffect(() => {
            const unsubscribe = Firebase.auth().onAuthStateChanged(onChange);

            // unsubscribe to the listener when unmounting
            return () => unsubscribe();
      }, []);
      return (
            <>
                  <Seo title="Output" />
                  <HomeLayout authPage={false}>
                        <main>
                              <div className="hero-section">
                                    <div
                                          className={
                                                isLoggedIn
                                                      ? userType === 'publisher'
                                                            ? ''
                                                            : 'hide-pricing'
                                                      : ''
                                          }
                                    >
                                          <h2
                                                style={{
                                                      maxWidth: 700,
                                                      fontWeight: 700,
                                                      fontSize: 24,
                                                }}
                                          >
                                                For Publishers
                                          </h2>
                                          <p>
                                                Turn your content into an API
                                                within minutes. Output lets you
                                                simplify your content
                                                syndication and distribution
                                                process by quickly generating
                                                industry standard feeds for
                                                distribution partners to access
                                                your content catalogs.
                                          </p>
                                          <p>
                                                Increase revenue and exposure by
                                                tapping into a select group of
                                                distribution partners. Utilize
                                                feeds with your own direct
                                                relationships.
                                          </p>
                                    </div>

                                    <div
                                          className={
                                                isLoggedIn
                                                      ? userType === 'publisher'
                                                            ? 'pricing-list'
                                                            : 'pricing-list hide-pricing'
                                                      : ''
                                          }
                                    >
                                          <Grid.Container gap={4}>
                                                {pricingOptionsPublishers.map(
                                                      (prObj, key) =>
                                                            prObj.price !==
                                                                  0 && (
                                                                  <Grid
                                                                        xs={24}
                                                                        md={8}
                                                                        key={
                                                                              key
                                                                        }
                                                                  >
                                                                        <Card
                                                                              width="100%"
                                                                              style={{
                                                                                    padding: 20,
                                                                                    backgroundColor:
                                                                                          key ===
                                                                                                1 &&
                                                                                          '#4353ff1a',
                                                                              }}
                                                                        >
                                                                              {key ===
                                                                              1 ? (
                                                                                    <Text
                                                                                          small
                                                                                    >
                                                                                          most
                                                                                          popular
                                                                                    </Text>
                                                                              ) : (
                                                                                    <div
                                                                                          style={{
                                                                                                height: 24,
                                                                                          }}
                                                                                    />
                                                                              )}
                                                                              <Text
                                                                                    h3
                                                                                    style={{
                                                                                          margin: 0,
                                                                                          padding: 0,
                                                                                    }}
                                                                              >
                                                                                    {
                                                                                          prObj.name
                                                                                    }
                                                                              </Text>
                                                                              <br />
                                                                              <br />
                                                                              <div
                                                                                    style={{
                                                                                          display: 'flex',
                                                                                          alignItems:
                                                                                                'baseline',
                                                                                    }}
                                                                              >
                                                                                    <Text
                                                                                          h2
                                                                                    >
                                                                                          $
                                                                                          {
                                                                                                prObj.price
                                                                                          }
                                                                                    </Text>
                                                                                    &nbsp;
                                                                                    <Text>
                                                                                          /
                                                                                          month
                                                                                    </Text>
                                                                              </div>
                                                                              <br />
                                                                              <br />

                                                                              {isLoggedIn ? (
                                                                                    <Checkout
                                                                                          plan={
                                                                                                prObj.planId
                                                                                          }
                                                                                          userId={`${userUid}-${prObj.name}-${prObj.apiCalls}-${prObj.channelsAllow}`}
                                                                                          uid={
                                                                                                userUid
                                                                                          }
                                                                                    />
                                                                              ) : (
                                                                                    <Link to="/signup">
                                                                                          <Button
                                                                                                width="100%"
                                                                                                type="primary"
                                                                                                style={{
                                                                                                      fontSize: 14,
                                                                                                }}
                                                                                          >
                                                                                                {prObj.price ===
                                                                                                0
                                                                                                      ? 'Start for free'
                                                                                                      : 'Signup Now'}
                                                                                          </Button>
                                                                                    </Link>
                                                                              )}
                                                                              <ul className="pricing-features">
                                                                                    {prObj.features.map(
                                                                                          (
                                                                                                f,
                                                                                                fKey
                                                                                          ) => (
                                                                                                <li
                                                                                                      key={
                                                                                                            fKey
                                                                                                      }
                                                                                                >
                                                                                                      <Text
                                                                                                            type="secondary"
                                                                                                            font="13px"
                                                                                                      >
                                                                                                            {
                                                                                                                  '> '
                                                                                                            }
                                                                                                            {
                                                                                                                  f
                                                                                                            }
                                                                                                      </Text>
                                                                                                </li>
                                                                                          )
                                                                                    )}
                                                                              </ul>
                                                                        </Card>
                                                                  </Grid>
                                                            )
                                                )}
                                          </Grid.Container>
                                    </div>

                                    <div
                                          className={
                                                isLoggedIn
                                                      ? userType ===
                                                        'distributor'
                                                            ? ''
                                                            : 'hide-pricing'
                                                      : ''
                                          }
                                    >
                                          <h2
                                                style={{
                                                      maxWidth: 700,
                                                      fontWeight: 700,
                                                      fontSize: 24,
                                                }}
                                          >
                                                For Distributors
                                          </h2>
                                          <p>
                                                Access channels from dozens of
                                                publishers & creators in simple
                                                no code one-click interface to
                                                produce an api for any channel
                                                we offer.
                                          </p>
                                          <p>
                                                Choose the amount of channels
                                                you want API access to,
                                                subscribe to that plan and
                                                browse from the channels to
                                                select.
                                          </p>
                                    </div>

                                    <div
                                          className={
                                                isLoggedIn
                                                      ? userType ===
                                                        'distributor'
                                                            ? 'pricing-list'
                                                            : 'pricing-list hide-pricing'
                                                      : ''
                                          }
                                    >
                                          <Grid.Container gap={4}>
                                                {pricingOptionsDist.map(
                                                      (prObj, key) => (
                                                            <Grid
                                                                  xs={24}
                                                                  md={8}
                                                                  key={key}
                                                            >
                                                                  <Card
                                                                        width="100%"
                                                                        style={{
                                                                              padding: 20,
                                                                              backgroundColor:
                                                                                    key ===
                                                                                          1 &&
                                                                                    '#4353ff1a',
                                                                        }}
                                                                  >
                                                                        {key ===
                                                                        1 ? (
                                                                              <Text
                                                                                    small
                                                                              >
                                                                              Most       
                                                                              Popular      
                                                                              </Text>
                                                                        ) : (
                                                                              <div
                                                                                    style={{
                                                                                          height: 24,
                                                                                    }}
                                                                              />
                                                                        )}
                                                                        <Text
                                                                              h3
                                                                        >
                                                                              {
                                                                                    prObj.name
                                                                              }
                                                                        </Text>
                                                                        <Text
                                                                              small
                                                                        >
                                                                              
                                                                        
                                                                        </Text>
                                                                        <br />
                                                                        <div
                                                                              style={{
                                                                                    display: 'flex',
                                                                                    alignItems:
                                                                                          'baseline',
                                                                              }}
                                                                        >
                                                                              <Text
                                                                                    h2
                                                                              >
                                                                                    $
                                                                                    {
                                                                                          prObj.price
                                                                                    }
                                                                              </Text>
                                                                              &nbsp;
                                                                              <Text>
                                                                                    /
                                                                                    month
                                                                              </Text>
                                                                        </div>
                                                                        <br />
                                                                        <br />

                                                                        {isLoggedIn ? (
                                                                              <Checkout
                                                                                    plan={
                                                                                          prObj.planId
                                                                                    }
                                                                                    userId={`${userUid}-${prObj.name}-${prObj.apiCalls}-${prObj.channelsAllow}`}
                                                                                    uid={
                                                                                          userUid
                                                                                    }
                                                                              />
                                                                        ) : (
                                                                              <Link to="/signup">
                                                                                    <Button
                                                                                          width="100%"
                                                                                          type="primary"
                                                                                          style={{
                                                                                                fontSize: 14,
                                                                                          }}
                                                                                    >
                                                                                          {prObj.price ===
                                                                                          0
                                                                                                ? 'Start for free'
                                                                                                : 'Signup Now'}
                                                                                    </Button>
                                                                              </Link>
                                                                        )}

                                                                        <ul className="pricing-features">
                                                                              {prObj.features.map(
                                                                                    (
                                                                                          f,
                                                                                          fKey
                                                                                    ) => (
                                                                                          <li
                                                                                                key={
                                                                                                      fKey
                                                                                                }
                                                                                          >
                                                                                                <Text
                                                                                                      type="secondary"
                                                                                                      font="13px"
                                                                                                >
                                                                                                      {
                                                                                                            '> '
                                                                                                      }
                                                                                                      {
                                                                                                            f
                                                                                                      }
                                                                                                </Text>
                                                                                          </li>
                                                                                    )
                                                                              )}
                                                                        </ul>
                                                                  </Card>
                                                            </Grid>
                                                      )
                                                )}
                                          </Grid.Container>
                                    </div>
                              </div>
                        </main>
                  </HomeLayout>
            </>
      );
};

export default PricingPage;
