import React from 'react';
import { Router } from '@reach/router';
import Usage from './usage';
import Billing from './billing';
import Docs from './docs';
import PublisherDash from './publisher/dashPublisher';

import { TfiMoney, TfiVideoClapper, TfiControlPlay } from 'react-icons/tfi';
import { Link } from 'gatsby';
import ChannelVideosContent from './publisher/channelVideosContent';
import { CgFeed } from 'react-icons/cg';
import MyChannels from './publisher/myChannels';
import Playground from './publisher/playground';

const PublisherBox = ({ location, uid, userType, userObj }) => {
      return (
            <div className="dash-content">
                  <div className="side-menu">
                        <ul>
                              <li>
                                    <Link to="/dashboard">
                                          <CgFeed />
                                          <span>Contents</span>
                                    </Link>
                              </li>
                              <li>
                                    <Link to="/dashboard/my-channels">
                                          <TfiVideoClapper />
                                          <span>Your Channels</span>
                                    </Link>
                              </li>
                              <li>
                                    <Link to="/dashboard/playground">
                                          <TfiControlPlay />
                                          <span>Playground</span>
                                    </Link>
                              </li>
                              <li>
                                    <Link to="/dashboard/billing">
                                          <TfiMoney />
                                          <span>Billing</span>
                                    </Link>
                              </li>
                        </ul>
                  </div>
                  <div>
                        <Router>
                              <ChannelVideosContent
                                    path="/dashboard"
                                    uid={uid}
                                    location={location}
                              />
                              <MyChannels
                                    path="/dashboard/my-channels"
                                    uid={uid}
                                    location={location}
                              />
                              <Playground
                                    path="/dashboard/playground"
                                    uid={uid}
                                    location={location}
                              />
                              <Billing
                                    path="/dashboard/billing"
                                    uid={uid}
                                    location={location}
                                    userObj={userObj}
                              />
                              <Docs
                                    path="/dashboard/docs"
                                    uid={uid}
                                    location={location}
                              />
                        </Router>
                  </div>
            </div>
      );
};

export default PublisherBox;
