import { Text } from '@geist-ui/core';
import { Link } from 'gatsby';
import React from 'react';

const Footer = () => {
      return (
            <footer>
                  <div className="footer">
                        <div className="about-company">
                              <img
                                    src="/output.png"
                                    height="30px"
                                    width="30px"
                              />
                              <br />
                              <Text small type="secondary">
                                    &copy; 2022 SD Technologies, Inc.
                              </Text>
                        </div>
                        <div className="footer-nav">
                              <div className="nav-col">
                                    <Text h4>Product</Text>
                                    <ul>
                                          <li>
                                                <Link to="/pricing">
                                                      Pricing
                                                </Link>
                                          </li>
                                          <li>
                                                <Link to="/roadmap">
                                                      Roadmap
                                                </Link>
                                          </li>
                                          <li>
                                                <Link to="/login">Login</Link>
                                          </li>
                                          <li>
                                                <Link to="/signup">Signup</Link>
                                          </li>
                                    </ul>
                              </div>
                              <div className="nav-col">
                                    <Text h4>Help & Support</Text>
                                    <ul>
                                          <li>
                                                <a
                                                      href="https://calendly.com/tompunch/15min"
                                                      target="_blank"
                                                >
                                                      Need help to start?
                                                </a>
                                          </li>
                                          {/* <li>
                                                <Link to="/privacy-policy">
                                                      QuickAPI vs RapidAPI
                                                </Link>
                                          </li> */}
                                    </ul>
                              </div>
                              <div className="nav-col">
                                    <Text h4>Community</Text>
                                    <ul>
                                          <li>
                                                <a
                                                      href="https://output.ws/slack"
                                                      target="_blank"
                                                >
                                                      Slack
                                                </a>
                                          </li>
                                          <li>
                                                <a
                                                      href="https://twitter.com/"
                                                      target="_blank"
                                                >
                                                      Twitter
                                                </a>
                                          </li>
                                          <li>
                                                <Link to="/privacy-policy">
                                                      Privacy Link
                                                </Link>
                                          </li>
                                          <li>
                                                <Link to="/terms">Terms</Link>
                                          </li>
                                    </ul>
                              </div>
                        </div>
                  </div>
            </footer>
      );
};

export default Footer;
