import { Loading, Select, Tabs, Text } from '@geist-ui/core';
import { Button, Drawer, Empty, Form, message, Table } from 'antd';
import axios from 'axios';
import { BsFillCloudCheckFill } from 'react-icons/bs';

import React, { useEffect, useState } from 'react';
import { randomString } from '../../../config/functions';
import AddChannel from '../../addChannel';
import ManualUpload from './manualUpload';
import { Link } from 'gatsby';

const ChannelVideosContent = ({ uid }) => {
      const [contentList, setContentList] = useState([]);
      const [openModal, setOpenModal] = useState(false);
      const [channelVideos, setChannelVideos] = useState([]);
      const [channelList, setChannelList] = useState([]);
      const [selectedVideos, setSelectedVideos] = useState([]);
      const [open, setOpen] = useState(false);
      const [triggerRefresh, setTriggerRefresh] = useState(0);
      const [uploadId, setUploadId] = useState('');
      const [loadingContent, setLoadingContent] = useState(false);
      const [nextPageToken, setNextPageToken] = useState(null);

      useEffect(() => {
            axios({
                  url: `${process.env.GATSBY_API_BASE_URL}/getContentVideos`,
                  method: 'post',
                  data: {
                        uid,
                  },
            }).then(({ data }) => {
                  setContentList(data);
            });
      }, [triggerRefresh]);

      const showDrawer = () => {
            setOpen(true);

            axios({
                  url: `${process.env.GATSBY_API_BASE_URL}/getChannel`,
                  method: 'post',
                  data: {
                        uid,
                  },
            }).then(({ data }) => {
                  setChannelList(data);
            });
      };

      const handler = (val) => {
            setUploadId(val);
            axios({
                  url: `${process.env.GATSBY_API_BASE_URL}/getChannelVideos?uid=${uid}&uploadsId=${val}`,
                  method: 'get',
            }).then(({ data }) => {
                  setChannelVideos(data.simpleArray);
                  if (data.nextPageToken === undefined) {
                        setNextPageToken(null);
                  } else {
                        setNextPageToken(data.nextPageToken);
                  }
            });
      };

      const loadMore = () => {
            if (nextPageToken !== null) {
                  setLoadingContent(true);
                  axios({
                        url: `${process.env.GATSBY_API_BASE_URL}/getChannelVideos?uid=${uid}&uploadsId=${uploadId}&nextPageToken=${nextPageToken}`,
                        method: 'get',
                  }).then(({ data }) => {
                        setChannelVideos([
                              ...channelVideos,
                              ...data.simpleArray,
                        ]);
                        setLoadingContent(false);

                        if (data.nextPageToken === undefined) {
                              setNextPageToken(null);
                        } else {
                              setNextPageToken(data.nextPageToken);
                        }
                  });
            } else {
                  message.info('no more videos');
            }
      };

      const onClose = () => {
            setOpen(false);
      };

      const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedVideos(selectedRows);
            },
            getCheckboxProps: (record) => ({
                  disabled:
                        contentList.findIndex(
                              (x) => x.videoId === record.videoId
                        ) > -1,
                  title: record.title,
            }),
      };

      const columns = [
            {
                  title: 'Thumbnail',
                  dataIndex: 'thumbnails',
                  key: 'thumbnails',
                  render: (obj) => (
                        <img
                              src={
                                    obj.medium
                                          ? obj.medium.url
                                          : obj.default.url
                              }
                              alt="hh"
                              height="60px"
                        />
                  ),
            },
            {
                  title: 'Title',
                  dataIndex: 'title',
                  key: 'title',
            },
            {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
            },
            {
                  title: 'videoId',
                  dataIndex: 'videoId',
                  key: 'videoId',
            },
            {
                  title: 'videoPublishedAt',
                  dataIndex: 'videoPublishedAt',
                  key: 'videoPublishedAt',
            },
      ];

      const contentColumns = [
            {
                  title: 'Thumbnail',
                  dataIndex: 'thumbnails',
                  key: 'thumbnails',
                  render: (obj) => (
                        <img
                              src={
                                    obj.medium
                                          ? obj.medium.url
                                          : obj.default.url
                              }
                              alt="hh"
                              height="60px"
                        />
                  ),
            },
            {
                  title: 'Title',
                  dataIndex: 'title',
                  key: 'title',
            },
            {
                  title: 'published',
                  dataIndex: 'videoPublishedAt',
                  key: 'videoPublishedAt',
            },
            {
                  title: 'Status',
                  dataIndex: 'status',
                  key: 'status',
                  render: (type) =>
                        type === 'done' ? (
                              <BsFillCloudCheckFill />
                        ) : (
                              <Loading />
                        ),
            },
            {
                  title: 'Watch Content',
                  dataIndex: 'url',
                  key: 'url',
                  render: (link) => (
                        <a href={link} target="_blank">
                              <Button type="primary" style={{ fontSize: 13 }}>
                                    View content
                              </Button>
                        </a>
                  ),
            },
      ];

      const importSelectedVideos = () => {
            const dataArray = [];
            for (let n = 0; n < selectedVideos.length; n++) {
                  const el = selectedVideos[n];
                  el.uid = uid;
                  el.objId = randomString(32, 'aA#');
                  dataArray.push({ data: el });
            }

            axios({
                  url: `${process.env.GATSBY_API_BASE_URL}/addVideosToProcess`,
                  method: 'post',
                  data: { dataArray, selectedVideos },
            }).then(({ data }) => {
                  setOpen(false);
                  setTriggerRefresh(triggerRefresh + 1);
            });
      };

      return (
            <div>
                  <Drawer
                        title="Add video content"
                        placement="left"
                        onClose={onClose}
                        open={open}
                        width="70%"
                  >
                        <Tabs initialValue="1">
                              <Tabs.Item label="YouTube Channels" value="1">
                                    <Text>Select the channel</Text>
                                    <Select
                                          placeholder="Choose channel"
                                          onChange={handler}
                                          width="300px"
                                    >
                                          {channelList.map((ch, key) => (
                                                <Select.Option
                                                      value={ch.uploadsId}
                                                      key={key}
                                                >
                                                      {ch.title}
                                                </Select.Option>
                                          ))}
                                    </Select>
                                    <br />
                                    <AddChannel
                                          openModal={openModal}
                                          uid={uid}
                                          closeModalBox={() =>
                                                setOpenModal(false)
                                          }
                                          setTriggerPage={() => showDrawer()}
                                          channelList={channelList}
                                    />
                                    <Text
                                          small
                                          type="secondary"
                                          style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                          }}
                                          onClick={() => setOpenModal(true)}
                                    >
                                          → Add more YouTube Channel
                                    </Text>
                                    <br />
                                    <br />
                                    {selectedVideos.length > 0 && (
                                          <div
                                                style={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      marginBottom: 20,
                                                }}
                                          >
                                                <Button
                                                      type="primary"
                                                      style={{ fontSize: 14 }}
                                                      onClick={
                                                            importSelectedVideos
                                                      }
                                                >
                                                      Import{' '}
                                                      {selectedVideos.length}{' '}
                                                      videos
                                                </Button>
                                          </div>
                                    )}
                                    <Table
                                          rowSelection={{
                                                type: 'checkbox',
                                                ...rowSelection,
                                          }}
                                          dataSource={channelVideos}
                                          columns={columns}
                                          rowKey="id"
                                          pagination={false}
                                    />
                                    <br />
                                    <div style={{ textAlign: 'center' }}>
                                          <Button
                                                type="primary"
                                                onClick={() => loadMore()}
                                                loading={loadingContent}
                                          >
                                                Load more content
                                          </Button>
                                    </div>
                              </Tabs.Item>
                              <Tabs.Item label="Manual Upload video" value="2">
                                    <ManualUpload
                                          uid={uid}
                                          setTriggerRefresh={() =>
                                                setTriggerRefresh(
                                                      triggerRefresh + 1
                                                )
                                          }
                                          setOpen={() => setOpen(false)}
                                    />
                              </Tabs.Item>
                        </Tabs>
                  </Drawer>
                  {contentList.length > 0 ? (
                        <>
                              <div
                                    style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          marginBottom: 30,
                                    }}
                              >
                                    <Text h3 style={{ margin: 0 }}>
                                          Your Channel Content
                                    </Text>
                                    <div>
                                          <Button
                                                style={{ fontSize: 14 }}
                                                onClick={() =>
                                                      setTriggerRefresh(
                                                            triggerRefresh + 1
                                                      )
                                                }
                                          >
                                                Refresh list
                                          </Button>
                                          &nbsp; &nbsp;
                                          <Button
                                                type="primary"
                                                style={{ fontSize: 14 }}
                                                onClick={showDrawer}
                                          >
                                                Import videos
                                          </Button>
                                    </div>
                              </div>
                              <Table
                                    dataSource={contentList}
                                    columns={contentColumns}
                                    size={50}
                                    rowKey="id"
                              />
                        </>
                  ) : (
                        <Empty
                              description="There is no video content imported, click on import button to add your channel videos."
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                        >
                              <Button
                                    type="primary"
                                    style={{ fontSize: 14 }}
                                    onClick={showDrawer}
                              >
                                    import videos
                              </Button>
                              &nbsp; &nbsp;
                              <Link to="/pricing">
                                    <Button style={{ fontSize: 14 }}>
                                          Subscribe a plan
                                    </Button>
                              </Link>
                        </Empty>
                  )}
            </div>
      );
};

export default ChannelVideosContent;
