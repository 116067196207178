import { Text } from '@geist-ui/core';
import React from 'react';

const Docs = () => {
      return (
            <div>
                  <Text>Docs</Text>
            </div>
      );
};

export default Docs;
