// https://www.placemark.io/
import React, { useEffect, useState } from 'react';
import { Loading, Text } from '@geist-ui/core';
import { Firebase } from '../config/firebase';
import { Link, navigate } from 'gatsby';

import '../styles/dashboard.scss';
import Seo from '../blocks/layout/seo';
import axios from 'axios';
import PublisherBox from '../blocks/dashboard/publisher';
import DistributorBox from '../blocks/dashboard/distributor';
import { randomString } from '../config/functions';
import { RiLogoutBoxLine } from 'react-icons/ri';

const Dashboard = ({ location }) => {
      const [uId, setUId] = useState('');
      const [email, setEmail] = useState('');
      const [isNewUser, setIsNewUser] = useState(true);
      const [loading, setLoading] = useState(true);
      const [triggerPage, setTriggerPage] = useState(0);
      const [userType, setUserType] = useState('');
      const [userObj, setUserObj] = useState(null);

      async function onChange(user) {
            if (user) {
                  const { data, error } = await axios({
                        url: `${process.env.GATSBY_API_BASE_URL}/getUser`,
                        method: 'post',
                        data: {
                              uid: user.uid,
                        },
                  });
                  if (data) {
                        setUserObj(data);
                        setIsNewUser(false);
                        setUserType(data.userType);
                  }
                  setEmail(user.email);
                  setUId(user.uid);
                  setLoading(false);
            } else {
                  navigate('/login');
            }
      }

      useEffect(() => {
            setLoading(true);
            const unsubscribe = Firebase.auth().onAuthStateChanged(onChange);

            // unsubscribe to the listener when unmounting
            return () => unsubscribe();
      }, [triggerPage]);

      const setupUser = async (val) => {
            const { data, error } = await axios({
                  url: `${process.env.GATSBY_API_BASE_URL}/addUser`,
                  method: 'post',
                  data: {
                        uid: uId,
                        email,
                        userType: val,
                        apiKey: randomString(16, 'aA#'),
                  },
            });
            setTriggerPage(triggerPage + 1);
      };

      const logoutUser = () => {
            Firebase.auth()
                  .signOut()
                  .then(
                        function () {
                              // Sign-out successful.
                        },
                        function (error) {
                              // An error happened.
                        }
                  );
      };

      return !isNewUser ? (
            <>
                  <div className="dashboard-container">
                        <Seo title="Dashboard - Output" />
                        <div className="app-header">
                              <div>
                                    <Link to="/" className="logo">
                                          <img
                                                src="/output.png"
                                                width="30px"
                                                height="30px"
                                                alt="logo"
                                          />
                                          <h3>Output</h3>
                                    </Link>
                              </div>
                              <div
                                    style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          cursor: 'pointer',
                                          gridGap: 20,
                                    }}
                              >
                                    <Link
                                          to="/pricing"
                                          style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                color: '#000',
                                          }}
                                    >
                                          <span style={{ marginLeft: 8 }}>
                                                Pricing Tiers
                                          </span>
                                    </Link>

                                    <div
                                          style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                          }}
                                          onClick={() => logoutUser()}
                                    >
                                          <RiLogoutBoxLine />
                                          <span style={{ marginLeft: 8 }}>
                                                Logout
                                          </span>
                                    </div>
                              </div>
                        </div>

                        {userType === 'publisher' && (
                              <PublisherBox
                                    uid={uId}
                                    location={location}
                                    userType={userType}
                                    userObj={userObj}
                              />
                        )}
                        {userType === 'distributor' && (
                              <DistributorBox
                                    uid={uId}
                                    location={location}
                                    userType={userType}
                                    userObj={userObj}
                                    setTriggerPage={() =>
                                          setTriggerPage(triggerPage + 1)
                                    }
                              />
                        )}
                  </div>
            </>
      ) : loading ? (
            <Loading>Loading</Loading>
      ) : (
            <>
                  <div className="user-onboard">
                        <Text>Choose, who you are.</Text>
                        <div className="user-selection">
                              <div
                                    className="publisher card-box"
                                    onClick={() => setupUser('publisher')}
                              >
                                    <Text h3>Publisher</Text>
                                    <Text type="secondary" small>
                                          Are you a publisher?
                                    </Text>
                              </div>
                              <div
                                    className="distributor card-box"
                                    onClick={() => setupUser('distributor')}
                              >
                                    <Text h3>Distributor</Text>
                                    <Text type="secondary" small>
                                          Are you a content distributor?
                                    </Text>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Dashboard;
