import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import Details from './details';
import Usage from './usage';
import Billing from './billing';
import Docs from './docs';

import {
      TfiHome,
      TfiBarChart,
      TfiMoney,
      TfiBook,
      TfiVideoClapper,
} from 'react-icons/tfi';
import { CgFeed } from 'react-icons/cg';
import { Link, navigate } from 'gatsby';
import { Text } from '@geist-ui/core';
import DistributorDash from './distributor/distributorDash';
import AllChannels from './distributor/allChannels';

const DistributorBox = ({
      location,
      uid,
      userType,
      userObj,
      setTriggerPage,
}) => {
      const channelInbox = userObj?.channelInbox || [];
      useEffect(() => {
            if (channelInbox.length === 0) navigate('/dashboard/channels');
      }, []);
      return (
            <div className="dash-content">
                  <div className="side-menu">
                        <ul>
                              <li>
                                    <Link to="/dashboard">
                                          <CgFeed />
                                          <span>Feeds</span>
                                    </Link>
                              </li>
                              <li>
                                    <Link to="/dashboard/channels">
                                          <TfiVideoClapper />
                                          <span>Channels</span>
                                    </Link>
                              </li>
                              <li>
                                    <Link to="/dashboard/usage">
                                          <TfiBarChart />
                                          <span>Usage</span>
                                    </Link>
                              </li>
                              <li>
                                    <Link to="/dashboard/billing">
                                          <TfiMoney />
                                          <span>Billing</span>
                                    </Link>
                              </li>
                        </ul>
                  </div>
                  <div>
                        <Router>
                              <DistributorDash
                                    path="/dashboard"
                                    uid={uid}
                                    location={location}
                                    userObj={userObj}
                              />
                              <AllChannels
                                    path="/dashboard/channels"
                                    uid={uid}
                                    location={location}
                                    channelInbox={channelInbox}
                                    setTriggerPage={setTriggerPage}
                              />
                              <Usage
                                    path="/dashboard/usage"
                                    uid={uid}
                                    location={location}
                              />
                              <Billing
                                    path="/dashboard/billing"
                                    uid={uid}
                                    location={location}
                                    userObj={userObj}
                              />
                        </Router>
                  </div>
            </div>
      );
};

export default DistributorBox;
