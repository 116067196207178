// https://www.placemark.io/
import { Fieldset, Text } from '@geist-ui/core';
import { Button } from 'antd';
import * as React from 'react';
import HomeLayout from '../blocks/layout/home';
import Seo from '../blocks/layout/seo';

const RoadmapPage = () => {
      return (
            <>
                  <Seo title="Output" />
                  <HomeLayout authPage={false}>
                        <main>
                              <div className="hero-section">
                                    <h2
                                          style={{
                                                maxWidth: 700,
                                                fontWeight: 700,
                                                fontSize: 24,
                                          }}
                                    >
                                          Roadmap
                                    </h2>
                                    <p>
                                          Keep track of Output's new product development and features here.  
                                    </p>
                                    <h3>Request a feature</h3>
                                    <p>
                                          If you have product feedback or a feature
                                          request, feel free to drop us an email!
                                    </p>
                                    <a url="https://www.youtube.com/watch?v=ASXJLo-sEHk">→ Request feature</a>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <Text h3>🧑‍💻 In progress</Text>
                                    <div className="task-lists">
                                          <div className="task-card">
                                                <div className="title">
                                                      <Text
                                                            style={{
                                                                  color: '#415659',
                                                            }}
                                                      >
                                                            Collections - Review, manage & edit
                                                            your content catalog in Collections. 
                                                            Adding search function by tag, 
                                                            keywords and other filters. 
                                                      </Text>
                                                </div>
                                                <div className="task-meta">
                                                      <Text
                                                            small
                                                            type="secondary"
                                                      >
                                                            Feature # 1
                                                      </Text>
                                                </div>
                                          </div>
                                          <div className="task-card">
                                                <div className="title">
                                                      <Text
                                                            style={{
                                                                  color: '#415659',
                                                            }}
                                                      >
                                                            TikTok Import - Like our 
                                                            Youtube Automated import, 
                                                            we are adding a feature to 
                                                            import your content from 
                                                            TikTok, without watermarks.
                                                      </Text>
                                                </div>
                                                <div className="task-meta">
                                                      <Text
                                                            small
                                                            type="secondary"
                                                      >
                                                            Feature # 2
                                                      </Text>
                                                </div>
                                          </div>
                                          <div className="task-card">
                                                <div className="title">
                                                      <Text
                                                            style={{
                                                                  color: '#415659',
                                                            }}
                                                      >
                                                            FAST - Extend your
                                                            distribution into
                                                            OTT channels once we
                                                            offer a FAST playout
                                                            API.
                                                      </Text>
                                                </div>
                                                <div className="task-meta">
                                                      <Text
                                                            small
                                                            type="secondary"
                                                      >
                                                            Feature # 3
                                                      </Text>
                                                </div>
                                          </div>
                                          <div className="task-card">
                                                <div className="title">
                                                      <Text
                                                            style={{
                                                                  color: '#415659',
                                                            }}
                                                      >
                                                            Custom Feeds -
                                                            Create, build and manage
                                                            custom feeds.                               
                                                      </Text>
                                                </div>
                                                <div className="task-meta">
                                                      <Text
                                                            small
                                                            type="secondary"
                                                      >
                                                            Feature # 4
                                                      </Text>
                                                </div>
                                          </div>
                                          <div className="task-card">
                                                <div className="title">
                                                      <Text
                                                            style={{
                                                                  color: '#415659',
                                                            }}
                                                      >
                                                            Teams - Manage your team with 
                                                            multiple users and user management. 
                                                      </Text>
                                                </div>
                                                <div className="task-meta">
                                                      <Text
                                                            small
                                                            type="secondary"
                                                      >
                                                            Feature # 5
                                                      </Text>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </main>
                  </HomeLayout>
            </>
      );
};

export default RoadmapPage;
