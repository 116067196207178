// https://www.placemark.io/
import { Fieldset, Text } from '@geist-ui/core';
import { Button } from 'antd';
import * as React from 'react';
import HomeLayout from '../blocks/layout/home';
import Seo from '../blocks/layout/seo';
import { HiOutlineUsers } from 'react-icons/hi';
import { FaRobot, FaCloud } from 'react-icons/fa';
import { TfiBoltAlt } from 'react-icons/tfi';
import { MdOutlineCodeOff, MdOutlineCloudUpload } from 'react-icons/md';
import { VscCheckAll } from 'react-icons/vsc';
import { BiPointer } from 'react-icons/bi';
import VideoPlayerBox from '../blocks/videpPlayerBox';
import VideoPlayerLoop from '../blocks/videoPlayerLoop';
import { Link } from 'gatsby';

const IndexPage = () => {
      return (
            <>
                  <Seo title="Output" />
                  <HomeLayout authPage={false}>
                        <main>
                              <div className="hero-section">
                                    <h2
                                          style={{
                                                maxWidth: 700,
                                                fontWeight: 700,
                                                fontSize: 24,
                                          }}
                                    >
                                          Output is a simple no code, cloud
                                          based API for content publishers,
                                          creators and distributors.
                                    </h2>
                                    <p>
                                          Turn your content catalog into a cloud
                                          based API product within minutes.
                                          Automate publishing to the API from
                                          your Youtube channel to reduce manual
                                          tasks and improve efficiency.
                                    </p>
                                    <Link to="/signup">
                                          <Button
                                                type="primary"
                                                style={{
                                                      fontSize: 14,
                                                }}
                                          >
                                                Start your API
                                          </Button>
                                    </Link>
                              </div>

                              <div className="section-two">
                                    <div className="features-list">
                                          <div className="feature-card">
                                                <div className="head">
                                                      <MdOutlineCodeOff
                                                            size={30}
                                                      />
                                                      <div className="feature-name">
                                                            No Code
                                                      </div>
                                                </div>
                                                <p>
                                                      No coding required to
                                                      build your partner APIs.
                                                </p>
                                          </div>
                                          <div className="feature-card">
                                                <div className="head">
                                                      <FaRobot size={30} />
                                                      <div className="feature-name">
                                                            Automation
                                                      </div>
                                                </div>
                                                <p>
                                                      Automated syncing of your
                                                      channels content, no
                                                      manual upload and entering
                                                      title, description,
                                                      metadata into a CMS
                                                </p>
                                          </div>
                                          <div className="feature-card">
                                                <div className="head">
                                                      <VscCheckAll size={30} />
                                                      <div className="feature-name">
                                                            Formats
                                                      </div>
                                                </div>
                                                <p>
                                                      Industry standardized MRSS
                                                      formats in JSON, XML, no
                                                      engineering or
                                                      customization needed
                                                </p>
                                          </div>
                                          <div className="feature-card">
                                                <div className="head">
                                                      <HiOutlineUsers
                                                            size={30}
                                                      />
                                                      <div className="feature-name">
                                                            Multi-Channel
                                                      </div>
                                                </div>
                                                <p>
                                                      Set up or access multiple
                                                      channels in a centralized
                                                      simple platform
                                                </p>
                                          </div>
                                          <div className="feature-card">
                                                <div className="head">
                                                      <MdOutlineCloudUpload
                                                            size={30}
                                                      />
                                                      <div className="feature-name">
                                                            Cloud Hosted
                                                      </div>
                                                </div>
                                                <p>
                                                      Centralized and accessible
                                                      by any remote team member,
                                                      no more navigating hard
                                                      drives or
                                                      uploading/downloading
                                                      files to google drives
                                                </p>
                                          </div>
                                          <div className="feature-card">
                                                <div className="head">
                                                      <TfiBoltAlt size={30} />
                                                      <div className="feature-name">
                                                            Performance
                                                      </div>
                                                </div>
                                                <p>
                                                      Speed of our cloud server
                                                      makes viewing and
                                                      syndicating large video
                                                      files instant
                                                </p>
                                          </div>
                                    </div>
                              </div>

                              <div className="section-three">
                                    <VideoPlayerBox
                                          url="https://www.youtube.com/watch?v=ASXJLo-sEHk"
                                          showControls
                                    />

                                    <div className="preview-item_line-column__EjniW">
                                          <span className="path-line_line__C6T5U path-line_stretch__j13md path-line_gradient__sKfKk geist-show-on-tablet" />
                                          <span className="path-circle_circle__CzQnd" />
                                    </div>
                                    <Fieldset
                                          style={{ border: 0 }}
                                          className="fieldset-zero-padding"
                                    >
                                          <Fieldset.Title>
                                                1. Add your YouTube channels
                                          </Fieldset.Title>
                                          <Fieldset.Subtitle
                                                style={{
                                                      fontSize: 16,
                                                      color: '#707070',
                                                      maxWidth: 500,
                                                      margin: '0 auto',
                                                }}
                                          >
                                                Get your channel IDs from your
                                                YouTube dashoard and add them
                                                here.
                                          </Fieldset.Subtitle>
                                    </Fieldset>
                                    <br />

                                    <VideoPlayerLoop url="https://output.sfo3.cdn.digitaloceanspaces.com/website/add-channel.mov" />

                                    <div className="preview-item_line-column__EjniW">
                                          <span className="path-line_line__C6T5U path-line_stretch__j13md path-line_gradient__sKfKk geist-show-on-tablet" />
                                          <span className="path-circle_circle__CzQnd" />
                                    </div>
                                    <Fieldset
                                          style={{ border: 0 }}
                                          className="fieldset-zero-padding"
                                    >
                                          <Fieldset.Title>
                                                2. Import videos
                                          </Fieldset.Title>
                                          <Fieldset.Subtitle
                                                style={{
                                                      fontSize: 16,
                                                      color: '#707070',
                                                }}
                                          >
                                                Select the channel and the
                                                videos you want to import to the
                                                cloud for distribution.
                                          </Fieldset.Subtitle>
                                    </Fieldset>
                                    <br />
                                    <VideoPlayerLoop url="https://output.sfo3.cdn.digitaloceanspaces.com/website/import-videos.mov" />

                                    <div className="preview-item_line-column__EjniW">
                                          <span className="path-line_line__C6T5U path-line_stretch__j13md path-line_gradient__sKfKk geist-show-on-tablet" />
                                          <span className="path-circle_circle__CzQnd" />
                                    </div>
                                    <Fieldset
                                          style={{ border: 0 }}
                                          className="fieldset-zero-padding"
                                    >
                                          <Fieldset.Title>
                                                3. Distributions
                                          </Fieldset.Title>
                                          <Fieldset.Subtitle
                                                style={{
                                                      fontSize: 16,
                                                      color: '#707070',
                                                }}
                                          >
                                                Distributors access video
                                                catalogs in industry standard
                                                MRSS feeds, both JSON & XML.
                                          </Fieldset.Subtitle>
                                    </Fieldset>
                                    <br />
                                    <VideoPlayerLoop url="https://output.sfo3.cdn.digitaloceanspaces.com/website/distributor.mov" />

                                    <div className="preview-item_line-column__EjniW">
                                          <span className="path-line_line__C6T5U path-line_stretch__j13md path-line_gradient__sKfKk geist-show-on-tablet" />
                                          <span className="path-circle_circle__CzQnd" />
                                    </div>
                                    <Fieldset
                                          style={{ border: 0 }}
                                          className="fieldset-zero-padding"
                                    >
                                          <Fieldset.Title>
                                                4. Done.
                                          </Fieldset.Title>
                                          <Fieldset.Subtitle
                                                style={{
                                                      fontSize: 16,
                                                      color: '#707070',
                                                }}
                                          >
                                                Now login to platform and start.
                                          </Fieldset.Subtitle>
                                          <Link to="/signup">
                                                <Button
                                                      width="200px"
                                                      type="primary"
                                                >
                                                      Signup now
                                                </Button>
                                          </Link>
                                    </Fieldset>
                                    <br />
                              </div>
                        </main>
                  </HomeLayout>
            </>
      );
};

export default IndexPage;
