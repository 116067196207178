import { Card, Grid, Image, Text } from '@geist-ui/core';
import axios from 'axios';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useEffect } from 'react';

const MyChannels = ({ uid }) => {
      const [channelList, setChannelList] = useState([]);

      useEffect(() => {
            axios({
                  url: `${process.env.GATSBY_API_BASE_URL}/getChannel`,
                  method: 'post',
                  data: {
                        uid,
                  },
            }).then(({ data }) => {
                  setChannelList(data);
            });
      }, []);
      return (
            <Grid.Container gap={1.5}>
                  {channelList.map((ch, key) => (
                        <Grid xs={12} justify="center" key={key}>
                              <Card width="100%">
                                    <img
                                          style={{
                                                width: 40,
                                                height: 40,
                                                borderRadius: '50%',
                                          }}
                                          src={ch.thumbnail}
                                    />
                                    <Text h4 my={0}>
                                          {ch.title}
                                    </Text>
                                    <Text style={{ fontSize: 14 }}>
                                          {ch.description.substring(0, 100)}
                                    </Text>
                                    <Card.Footer>
                                          <Link
                                                color
                                                target="_blank"
                                                href={`https://www.youtube.com/channel/${ch.cId}`}
                                          >
                                                Channel ID
                                          </Link>
                                    </Card.Footer>
                              </Card>
                        </Grid>
                  ))}
            </Grid.Container>
      );
};

export default MyChannels;
