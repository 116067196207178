export const pricingOptionsPublishers = [
      {
            name: 'Pro Creator',
            description: 'Perfect for small businesses.',
            price: 490,
            apiCalls: 250000,
            channelsAllow: 1,
            planId: process.env.GATSBY_PUB_CREATOR,
            features: [
                  '1 Channel',
                  'Youtube Automation',
                  'Entry Distribution Network',
                  'API usage up to 250,000 per month',
                  'Cloud Encoding',
                  'Cloud Hosting',
                  'JSON, XML MRSS Feeds',
            ],
      },
      {
            name: 'Launch',
            description: 'Perfect for growing subscription businesses.',
            price: 790,
            apiCalls: 500000,
            channelsAllow: 3,
            planId: process.env.GATSBY_PUB_LAUNCH,
            features: [
                  'Up to 3 Channels',
                  'Youtube Automation',
                  'Premium Distribution',
                  'API usage up to 500,000 per month',
                  'Cloud Encoding',
                  'Cloud Hosting',
                  'JSON, XML MRSS Feeds',
                  'Video Call Support',
            ],
      },
      {
            name: 'Business',
            description: 'Perfect for a high-traffic product site.',
            price: 1290,
            apiCalls: 1000000,
            channelsAllow: 7,
            planId: process.env.GATSBY_PUB_BUSINESS,
            features: [
                  'Up to 7 Channels',
                  'Youtube Automation',
                  'Enterprise Distribution',
                  'API usage up to 1,000,000 per month',
                  'Cloud Encoding',
                  'Cloud Hosting',
                  'JSON, XML MRSS Feeds',
                  'Video Call Support',
            ],
      },
];

export const pricingOptionsDist = [
      {
            name: 'Starter',
            description: 'Perfect for small businesses.',
            price: 790,
            apiCalls: 250000,
            channelsAllow: 1,
            planId: process.env.GATSBY_DIST_STARTER,
            features: [
                  '1 Channel',
                  'API usage up to 250,000 per month',
                  'Cloud Encoding',
                  'Cloud Hosting',
                  'JSON, XML MRSS Feeds',
            ],
      },
      {
            name: 'Launch',
            description: 'Perfect for growing subscription businesses.',
            price: 1290,
            apiCalls: 500000,
            channelsAllow: 4,
            planId: process.env.GATSBY_DIST_LAUNCH,
            features: [
                  '2-4 Channels',
                  'API usage up to 500,000 per month',
                  'Cloud Encoding',
                  'Cloud Hosting',
                  'JSON, XML MRSS Feeds',
                  'Video Call Support',
            ],
      },
      {
            name: 'Enterprise',
            description: 'Perfect for a high-traffic product site.',
            price: 1990,
            apiCalls: 1000000,
            channelsAllow: 7,
            planId: process.env.GATSBY_DIST_ENTERPRISE,
            features: [
                  '5-7 Channels',
                  'API usage up to 1,000,000 per month',
                  'Cloud Encoding',
                  'Cloud Hosting',
                  'JSON, XML MRSS Feeds',
                  'Video Call Support',
            ],
      },
];
