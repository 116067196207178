import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import '../styles/video.scss';

const VideoPlayerLoop = ({ url }) => {
      return (
            <div className="video-player">
                  <ReactPlayer
                        url={url}
                        playing
                        loop
                        muted
                        width="100%"
                        // config={{
                        //       file: {
                        //             attributes: {
                        //                   poster: product.videoThumbnail,
                        //             },
                        //       },
                        // }}
                        className="react-player"
                  />
            </div>
      );
};

export default VideoPlayerLoop;
