import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import { FiPlay, FiPause } from 'react-icons/fi';

import '../styles/video.scss';

const VideoPlayerBox = ({ url }) => {
      const [isPlaying, setIsPlaying] = useState(false);

      return (
            <div
                  className="video-player"
                  onClick={() => setIsPlaying(!isPlaying)}
            >
                  <div className="video-header">
                        {isPlaying ? <FiPause /> : <FiPlay />}{' '}
                        <span>Watch this demo video</span>
                  </div>
                  <ReactPlayer
                        url={url}
                        playing={isPlaying}
                        width="100%"
                        className="react-player-2"
                  />
            </div>
      );
};

export default VideoPlayerBox;
