import { Button } from 'antd';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Firebase } from '../../config/firebase';

import '../../styles/home.scss';
import Footer from './footer';

const HomeLayout = ({ children, authPage }) => {
      const [isLoggedIn, setIsLoggedIn] = useState(false);

      async function onChange(user) {
            if (user) {
                  setIsLoggedIn(true);
            }
      }

      useEffect(() => {
            const unsubscribe = Firebase.auth().onAuthStateChanged(onChange);

            // unsubscribe to the listener when unmounting
            return () => unsubscribe();
      }, []);
      return (
            <div>
                  <header>
                        <div className="header">
                              <div className="nav-section">
                                    <Link to="/" className="logo">
                                          <img
                                                src="/output.png"
                                                width="30px"
                                                height="30px"
                                                alt="logo"
                                          />
                                          <h3>Output</h3>
                                    </Link>
                                    <div className="nav-menu">
                                          <ul>
                                                {!authPage && (
                                                      <li>
                                                            <Link to="/pricing">
                                                                  Pricing
                                                            </Link>
                                                      </li>
                                                )}
                                                {!authPage && (
                                                      <li>
                                                            <Link to="/roadmap">
                                                                  Roadmap
                                                            </Link>
                                                      </li>
                                                )}

                                                {!isLoggedIn && (
                                                      <li>
                                                            <Link to="/login">
                                                                  Login
                                                            </Link>
                                                      </li>
                                                )}

                                                {!isLoggedIn ? (
                                                      <li>
                                                            <Link to="/signup">
                                                                  <Button
                                                                        type="primary"
                                                                        width="100px"
                                                                        style={{
                                                                              fontSize: 14,
                                                                        }}
                                                                  >
                                                                        Signup
                                                                  </Button>
                                                            </Link>
                                                      </li>
                                                ) : (
                                                      <li>
                                                            <Link to="/dashboard">
                                                                  <Button
                                                                        type="primary"
                                                                        width="100px"
                                                                        style={{
                                                                              fontSize: 14,
                                                                        }}
                                                                  >
                                                                        Go to
                                                                        dashboard
                                                                  </Button>
                                                            </Link>
                                                      </li>
                                                )}
                                          </ul>
                                    </div>
                              </div>
                        </div>
                  </header>
                  <div className="container">{children}</div>
                  <Footer />
            </div>
      );
};

export default HomeLayout;
