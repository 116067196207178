import { Form, Input, message, Modal, Button } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const AddChannel = ({
      openModal,
      closeModalBox,
      uid,
      setTriggerPage,
      channelList,
}) => {
      const addChannelForm = async (values) => {
            const { ytChannelId } = values;
            const isUnique = channelList.findIndex(
                  (x) => x.cId === ytChannelId
            );

            if (isUnique === -1) {
                  const { data } = await axios({
                        url: `${process.env.GATSBY_API_BASE_URL}/addChannel`,
                        method: 'post',
                        data: {
                              uid,
                              ytChannelId,
                        },
                  });

                  const { error } = data;

                  if (error) {
                        message.info(error);
                  } else {
                        message.success('Channel Added.');
                  }

                  closeModalBox();

                  setTriggerPage();
            } else {
                  message.error('this channel is already added');
            }
      };

      return (
            <Modal
                  title="Add YT channel"
                  footer={null}
                  open={openModal}
                  onCancel={closeModalBox}
                  layout="vertical"
            >
                  <Form
                        name="ytChannelAdd"
                        initialValues={{ remember: false }}
                        onFinish={addChannelForm}
                        onFinishFailed={() =>
                              message.error(
                                    'there are some error in inputting.'
                              )
                        }
                        autoComplete="off"
                  >
                        <Form.Item
                              name="ytChannelId"
                              rules={[
                                    {
                                          required: true,
                                    },
                              ]}
                        >
                              <Input placeholder="Input YouTube channel ID" />
                        </Form.Item>

                        <Form.Item>
                              <Button
                                    type="primary"
                                    scale={2 / 3}
                                    htmlType="submit"
                              >
                                    Add channel
                              </Button>
                        </Form.Item>
                  </Form>
            </Modal>
      );
};

export default AddChannel;
